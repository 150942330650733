import ImageDefault from "./assets/images/image.png";
import Logo2 from "./assets/images/logo2.png";
import "./App.css";

function App() {
  // eslint-disable-next-line
  return (
    <>
      <header className="bg-white shadow">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <a href="/" className="text-2xl font-bold text-blue-600 logo">
            <img src={Logo2} className="object-cover" />
          </a>
          <nav className="space-x-4">
            <a href="/" className="text-gray-600 hover:text-blue-600">
              Home
            </a>
            <a href="/" className="text-gray-600 hover:text-blue-600">
              Koleksi
            </a>
            <a href="/" className="text-gray-600 hover:text-blue-600">
              Kontak
            </a>
          </nav>
        </div>
      </header>

      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl font-bold mb-4">
            Temukan Aplikasi yang Menginspirasi
          </h1>
          <p className="text-lg mb-6">
            Jelajahi koleksi aplikasi yang unik dan inovatif.
          </p>
          <a
            href="#showcase"
            className="bg-white text-blue-600 font-semibold py-2 px-4 rounded hover:bg-gray-200 transition"
          >
            Jelajahi Sekarang
          </a>
        </div>
      </section>

      <section id="showcase" className="py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-10">
            Koleksi Aplikasi
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-white shadow rounded-lg overflow-hidden">
              <img
                src={ImageDefault}
                alt="App 1"
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2">Aplikasi Absensi</h3>
                <p className="text-gray-600 mb-4">
                  Aplikasi sederhana yang mencatan absensi masuk dan pulang.
                </p>
                <a
                  href="/"
                  className="text-blue-600 font-semibold hover:underline"
                >
                  Selengkapnya
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="bg-white py-6">
        <div className="container mx-auto px-4 text-center">
          <p className="text-gray-600">
            &copy; 2024 AppSample. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}

export default App;
